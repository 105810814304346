<template>
  <div>
    <v-col>
      <Card>
        <v-card-subtitle>
          <span class="blue--text text-h6"> Kullanıcı Ekle</span>
        </v-card-subtitle>
        <v-divider />
        <v-card-text class="mt-0 pt-0">
          <div class="col-12 d-flex">
            <Button
              v-if="btnControl()"
              class="mt-2"
              outlined
              color="indigo"
              dense
              @click="newuser"
            >
              <v-icon>mdi-account</v-icon>
              Ekle
            </Button>

            <v-spacer />
            <v-col cols="2">
              <v-select
                v-model="userRoleFilter"
                :items="userRoleList"
                label="Kullanıcı Rolü"
                class="mb-0 mt-0 pt-0 pb-0"
                outlined
                dense
                required
                multiple
                hide-details
              >
                <template v-slot:selection="{item, index}">
                  <span
                    v-if="index === 0"
                    style="max-width: 120px"
                    class="d-inline-block text-truncate"
                  >{{ item.text
                  }}</span>
                  <span
                    v-if="index === 1"
                    class="grey--text text-caption"
                  >(+{{ userRoleFilter.length - 1 }})</span>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="3"
              class="d-flex"
            >
              <v-text-field
                v-model="search"
                label="Ara"
                single-line
                outlined
                dense
                hide-details
                append-outer-icon="mdi-account-search"
              />
            </v-col>
            <v-col
              cols="1"
              class="  "
            >
              <Button
                color="primary"
                :min-width="'30px'"
                :elevation="0"
                :outlined="true"
                @click="getUser"
              >
                Filtrele
              </Button>
            </v-col>
          </div>
        </v-card-text>

        <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page.sync="itemsPerPage"
          class="elevation-1"
          dense
        >
          <template v-slot:item.status="{item}">
            <Chips
              :payload="{
                color: item.status === 'Active' ? 'green' : 'red',
                text: item.status === 'Active' ? 'Aktif' : 'Pasif',
                textColor: 'white',
                small: true
              }"
            />
          </template>
          <template v-slot:item.clientId="{item}">
            <v-icon

              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </Card>

      <Dialog
        v-model="createDialog"
        :persistent="true"
        max_width="700px"
        min_height="400px"
        width="700px"
        height="500px"
      >
        <Card height="500px">
          <v-card-title class="text-h5 ">
            Kullanıcı Ekle
            <v-spacer />
            <Button
              :elevation="0"
              @click="createDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </Button>
          </v-card-title>
          <v-divider />

          <v-card-text class="mt-4">
            <v-row id="userForm">
              <v-form
                ref="form"
                v-model="valid"
                class="col-12 mt-3"
                :lazy-validation="lazy"
              >
                <v-col cols="12 ">
                  <v-text-field
                    ref="formData.username"
                    v-model="formData.username"
                    :counter="50"
                    :rules="usernameRules"
                    outlined
                    dense
                    hidden-footer
                    hidden-details
                    hidden-hint
                    label="Kullanıcı Adı (mail)"
                    required
                  />

                  <v-text-field
                    ref="formData.firstname"
                    v-model="formData.firstname"
                    :counter="50"
                    :rules="surnameRules"
                    outlined
                    dense
                    hidden-footer
                    hidden-details
                    hidden-hint
                    label="Adı"
                    required
                  />

                  <v-text-field
                    ref="formData.lastname"
                    v-model="formData.lastname"
                    :counter="50"
                    :rules="surnameRules"
                    outlined
                    dense
                    hidden-footer
                    hidden-details
                    hidden-hint
                    label="Soyadı"
                    required
                  />

                  <v-select
                    ref="formData.userRole"
                    v-model="formData.userRole[0]"
                    :items="userRoleList"
                    label="Kullanıcı Rolü"
                    outlined
                    dense
                    required
                    hidden-footer
                    hidden-details
                    hidden-hint
                  />

                  <v-combobox
                    ref="formData.email"
                    v-model="formData.email"
                    :items="mailList"
                    chips
                    clearable
                    :label="$t('email')"
                    multiple
                    outlined
                    hidden-footer
                    hidden-details
                    hidden-hint
                    dense
                  >
                    <template v-slot:selection="{attrs, item, select, selected}">
                      <v-chip
                        small
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        dense
                        @click="select"
                        @click:close="remove(item)"
                      >
                        <strong>{{ item }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>

                <Button
                  class="mt-2"
                  :outlined="true"
                  :dense="true"
                  color="indigo"

                  @click="createUser"
                >
                  Kaydet
                </Button>
              </v-form>
            </v-row>
          </v-card-text>
        </Card>
      </Dialog>

      <Dialog
        v-model="editDialog"
        :persistent="true"
        max_width="700px"
        min_height="400px"
        width="700px"
      >
        <Card height="550px">
          <v-card-title class="text-h5  ">
            Kullanıcı Düzenle
            <v-spacer />
            <Button
              :elevation="0"
              @click="editDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </Button>
          </v-card-title>
          <v-divider />

          <v-card-text class="mt-4">
            <v-tabs
              v-model="tab"
              fixed-tabs
              background-color="blue-grey lighten-5"
              class="mt-4"
            >
              <v-tabs-slider />

              <v-tab href="#tab-1">
                Kişisel Bilgiler
                <v-icon>mdi-account-box</v-icon>
              </v-tab>

              <v-tab href="#tab-2">
                Parola Degiştir
                <v-icon>mdi-lock</v-icon>
              </v-tab>

              <v-tab href="#tab-3">
                Rol ve İzinler
                <v-icon> mdi-server</v-icon>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <v-row id="userForm">
                  <v-form
                    ref="form"
                    v-model="valid"
                    class="col-12 mt-4"
                    :lazy-validation="lazy"
                  >
                    <v-col cols="12 ">
                      <v-text-field
                        ref="formData.username"
                        v-model="formData.username"
                        :counter="50"
                        :rules="usernameRules"
                        outlined
                        dense
                        hidden-footer
                        hidden-details
                        hidden-hint
                        label="Kullanıcı Adı (mail)"
                        required
                      />

                      <v-text-field
                        ref="formData.firstname"
                        v-model="formData.firstname"
                        :counter="50"
                        :rules="surnameRules"
                        outlined
                        dense
                        hidden-footer
                        hidden-details
                        hidden-hint
                        label="Adı"
                        required
                      />

                      <v-text-field
                        ref="formData.lastname"
                        v-model="formData.lastname"
                        :counter="50"
                        :rules="surnameRules"
                        outlined
                        dense
                        hidden-footer
                        hidden-details
                        hidden-hint
                        label="Soyadı"
                        required
                      />

                      <v-select
                        ref="formData.userRole"
                        v-model="formData.userRole[0]"
                        :items="userRoleList"
                        label="Kullanıcı Rolü"
                        outlined
                        dense
                        required
                        hidden-footer
                        hidden-details
                        hidden-hint
                      />

                      <v-combobox
                        ref="formData.email"
                        v-model="formData.email"
                        :items="mailList"
                        chips
                        clearable
                        :label="$t('email')"
                        multiple
                        outlined
                        hidden-footer
                        hidden-details
                        hidden-hint
                        dense
                      >
                        <template v-slot:selection="{attrs, item, select, selected}">
                          <v-chip
                            small
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            dense
                            @click="select"
                            @click:close="remove(item)"
                          >
                            <strong>{{ item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>

                    <Button
                      class="mt-2"
                      :outlined="true"
                      :dense="true"
                      color="indigo"

                      @click="updateUser"
                    >
                      Güncelle
                    </Button>
                  </v-form>
                </v-row>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-row id="userForm">
                  <v-form
                    ref="form"
                    v-model="valid"
                    class="col-12 mt-4"
                    :lazy-validation="lazy"
                  >
                    <v-col cols="12 ">
                      <div class="d-flex  ">
                        <v-col cols="6">
                          <v-text-field
                            ref="password"
                            v-model="password"
                            outlined
                            dense
                            hidden-footer
                            hidden-details
                            hidden-hint
                            label="Şifre"
                            required
                          />
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            ref="passwordConfirm"
                            v-model="passwordConfirm"
                            outlined
                            dense
                            hidden-footer
                            hidden-details
                            hidden-hint
                            label="Şifre Tekrar"
                            required
                          />
                        </v-col>
                      </div>

                      <v-col cols="12 ml-3">
                        <Button
                          class="mt-2"
                          :outlined="true"
                          :dense="true"
                          color="indigo"

                          @click="setUserPass"
                        >
                          Degiştir
                        </Button>
                      </v-col>
                    </v-col>
                  </v-form>
                </v-row>
              </v-tab-item>
              <v-tab-item value="tab-3">
                <v-col cols="12  mt-4">
                  <v-select
                    ref="formData.companyCodeList"
                    v-model="companyCodeModel"
                    :items="companyList"
                    label="Şirket"
                    outlined
                    multiple
                    dense
                    hidden-footer
                    hidden-details
                    hidden-hint
                  />

                  <v-select
                    ref="formData.prefixList"
                    v-model="prefixListModel"
                    :items="farmList"
                    label="Saha Ekle"
                    outlined
                    dense
                    multiple
                    hidden-footer
                    hidden-details
                    hidden-hint
                  />
                </v-col>
                <v-col cols="12 ml-1 mt-0 pt-0">
                  <Button
                    :outlined="true"
                    :dense="true"
                    color="indigo"
                    @click="updatePermision"
                  >
                    Düzenle
                  </Button>
                </v-col>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </Card>
      </Dialog>
    </v-col>

    <Snackbar
      :notify="notify"
    />
  </div>
</template>

<script>

import i18n from '@/locale/index';
import { getCompanyFarmLists } from '@/api/Request/farm';
import { getCompanyList1 } from '@/api/Request/company';
import {
  getRoleList, getUserList, userCreate, userEdit, userDelete, userPassword, userPermision,
} from '@/api/Request/user';
import helper from '@/api/helper';

export default {
  data: () => ({
    desserts: [],
    itemPerPage: 15,
    itemsPerPage: 15,
    dialog: false,
    tab: null,
    lastname: '',
    firstname: '',
    username: '',
    prefix: '',
    clientId: '',
    userRole: ['manager'],
    status: '',
    phone: '',

    prefixList: [],
    password: '',
    passwordConfirm: '',
    search: '',
    company_name: '',
    valid: true,
    lazy: true,
    firstnameRules: [
      (v) => !!v || i18n.t('Bu alan boş bırakılamaz.'),
      (v) => (v && v.length <= 50) || i18n.t('name_50'),
    ],
    userroleRules: [(v) => !!v || i18n.t('Bu alan boş bırakılamaz.')],
    userstatuRules: [(v) => !!v || i18n.t('Bu alan boş bırakılamaz.')],

    surnameRules: [
      (v) => !!v || i18n.t('Bu alan boş bırakılamaz.'),
      (v) => (v && v.length <= 50) || i18n.t('surname_50'),
    ],
    gsmRules: [
      (v) => !!v || i18n.t('Bu alan boş bırakılamaz.'),
      (v) => (v && v.length === 16) || i18n.t('gsm_11'),
    ],
    passwordRules: [
      (v) => {
        if (v != null && v) {
          const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;

          if (v.length > 0) {
            if (v.match(passw)) {
              return true;
            }
            return i18n.t(
              "Şifreniz en az 8, en fazla 20 karakter olmalıdır.En az bir büyük harf,bir küçük harf, bir rakam ve bir özel karakter (! ? $ ? % ^ & * ( ) _ - + = { [ } ] : ; @ ' ~ # | < , > . ? /) içermelidir.",
            );
          }

          if (v.length > 0 && v.length < 8) {
            return i18n.t('Şifre en az 8 karakter olmalıdır.');
          }
          if (v.length > 20) {
            return i18n.t('Şifre en az 20 karakter olmalıdır.');
          }
          return true;
        }
        return i18n.t('This field cannot be left blank.');
      },
    ],
    usernameRules: [
      (v) => {
        if (!v || v.length < 1) return i18n.t('Bu alan boş bırakılamaz.');
        if (v.length > 0) {
          if (
            !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
              v,
            )
          ) {
            return i18n.t('Kullanıcı adınız email olmalıdır.');
          }
          return true;
        }
        return true;
      },
    ],
    emailRules: [
      (v) => {
        if (!v || v.length < 1) return i18n.t('Bu alan boş bırakılamaz.');
        if (v.length > 0) {
          for (let i = 0; i < v.length; i++) {
            if (
              !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
                v[i],
              )
            ) {
              // this.remove(i);
            }
          }
          return true;
        }
        return true;
      },
    ],
    passConfirmRules: [
      (v) => {
        if (!v) {
          return i18n.t('This field cannot be left blank.');
        }
        if (this.password_val !== v) {
          return i18n.t('Şifreler uyuşmuyor.');
        }
        return true;
      },
    ],

    userRoleList: [],
    edit: false,
    farmList: [],
    farmModel: [],
    update: false,
    isAdmin: false,
    managerModel: null,
    companyCode: null,
    managerList: [],
    mailList: [],
    email: [],
    companyName: [],
    companyList: [],
    userRoleFilter: [],

    notify: {
      snackbar: false,
      text: '',
      vertical: true,
      color: 'success',
    },

    formData: {
      companyCodeList: '',
      username: '',
      firstname: '',
      lastname: '',
      password: '',
      prefixList: [],
      userRole: [],
      email: [],
      adminCompanyCode: '',
    },
    createDialog: false,
    editDialog: false,
    companyCodeModel: [],
    prefixListModel: [],
  }),
  computed: {
    headers() {
      return [
        { text: 'Kullanıcı Adı', value: 'username' },
        {
          text: 'Adı',
          value: 'firstname',
        },
        { text: 'Soyadı', value: 'lastname' },
        { text: 'Durum', value: 'status' },
        { text: 'Rolü', value: 'userRoleText' },
        { text: 'email', value: 'email' },

        { text: '', value: 'clientId' },
      ];
    },
  },
  watch: {
    companyCodeModel: {
      deep: true,
      handler() {
        // save the formdata from here for immediate updates if you want that.
        setTimeout(() => {
          this.getFarmList();
        }, 500);
      },
    },

    userRoleFilter() {
      this.getUser();
    },
  },
  created() {

  },
  mounted() {
    setTimeout(() => {
      const storage = window.localStorage;
      // eslint-disable-next-line no-underscore-dangle
      const _token = helper.parseJwt(storage.getItem('vuex_user'));
      this.formData.adminCompanyCode = _token.data.adminCompanyCode;
      this.getRole();
      this.getUser();
      this.getCompanyLists();
    }, 1000);
  },
  methods: {
    setStatus(item) {
      return item === 'Active' ? 'Aktif' : 'Pasif';
    },
    remove(item) {
      const index = this.mailList.indexOf(item.name);
      if (index >= 0) this.mailList.splice(index, 1);
    },
    newuser() {
      this.createDialog = true;
      this.formData = {
        companyCodeList: '',
        username: '',
        firstname: '',
        lastname: '',
        password: '',
        prefixList: [],
        userRole: [],
        email: [],
        adminCompanyCode: this.formData.adminCompanyCode,
      };
    },
    btnControl() {
      const role = this.$store.getters.roles[0];
      const roleList = ['admin', 'manager'];

      if (roleList.indexOf(role) > -1) {
        return true;
      }
      return false;
    },
    getRole() {
      getRoleList().then((res) => {
        const role = [];
        const rl = [];

        res.data.data.forEach((rol) => {
          if (rol.rolename !== 'admin') {
            role.push({
              text: rol.roletext,
              value: rol.rolename,
              roleId: rol.roleId,
            });
            rl.push(rol.rolename);
          }
        });

        this.userRoleList = role;
        this.formData.userRole = [];
      });
    },
    getUser() {
      if (!helper.is_Empty(this.formData.adminCompanyCode)) {
        const params = {
          condiniton: {
            userRole: this.userRoleFilter,
            adminCompanyCode: this.formData.adminCompanyCode,
          },
        };

        getUserList(params).then((res) => {
          if (res.data.success === 'false') {
            this.notify.snackbar = true;
            this.notify.text = res.data.message;
            this.notify.vertical = true;
            this.notify.color = 'red';
            return;
          }

          if (helper.resIsEmpty(res)) {
            // this.desserts = res.data.data;
            res.data.data.forEach((item) => {
              // eslint-disable-next-line no-param-reassign
              item.userRoleText = '';
              if ('userRole' in item) {
                const rol = item.userRole[0];
                const f = this.userRoleList.filter((i) => i.value === rol);
                if (!helper.is_Empty(f)) {
                  // eslint-disable-next-line no-param-reassign
                  item.userRoleText = f[0].text;
                }
              }
            });

            this.desserts = res.data.data;
          }
        });
      }
    },

    getCompanyLists() {
      const params = {
        adminCompanyCode: this.formData.adminCompanyCode,
      };

      getCompanyList1(params).then((res) => {
        const companyList = [];
        Object.keys(res.data.data).forEach((i) => {
          companyList.push({
            text: res.data.data[i].companyName,
            value: res.data.data[i].companyCode,
          });
        });
        this.companyList = companyList;
      });
    },

    getFarmList() {
      if (this.companyCodeModel.length > 0) {
        const params = {
          condiniton: {
            companyCode: this.companyCodeModel,
            status: 'Active',
          },
        };
        this.farmList = [];
        getCompanyFarmLists(params).then((res) => {
          const farmlist = [];
          Object.keys(res.data.data).forEach((i) => {
            const { companyCode } = res.data.data[i];
            farmlist.push({
              text: res.data.data[i].farmName,
              value: `${companyCode}_${res.data.data[i].prefix}`,
            });
          });
          this.farmList = farmlist;
        });
      }
    },

    createUser() {
      const params = {
        data: this.formData,
      };
      if (Array.isArray(this.formData.userRole)) {
        params.data.userRole = this.formData.userRole;
      } else {
        params.data.userRole = [this.formData.userRole];
      }

      if (this.formData.userRole.length === 0) {
        this.notify.snackbar = true;
        this.notify.text = 'Kullanıcı rolü seçiniz';
        this.notify.vertical = true;
        this.notify.color = 'red';
        return;
      }

      if (this.$refs.form.validate()) {
        userCreate(params).then((res) => {
          if (res.data.success === 'false') {
            this.notify.snackbar = true;
            this.notify.text = res.data.message;
            this.notify.vertical = true;
            this.notify.color = 'red';
          }
          if (res.data.success === 'true') {
            this.notify.snackbar = true;
            this.notify.text = 'Kullanıcı oluşturuldu.';
            this.notify.vertical = true;
            this.notify.color = 'success';
          }
          this.getUser();
        });
      }
    },
    close() {
      this.update = false;
      this.dialog = false;
    },
    editItem(item) {
      this.formData = item;
      this.companyCodeModel = item.companyCodeList;
      this.prefixListModel = item.prefixList;

      this.update = true;
      this.editDialog = true;
    },
    // userPassword
    setUserPass() {
      const params = {
        condiniton: {
          clientId: this.formData.clientId,
        },
        data: {
          password: this.password,
          passwordConfirm: this.passwordConfirm,
        },
      };
      if (this.password.length < 6) {
        this.notify.snackbar = true;
        this.notify.text = 'Şifre en az 6 karakter olmalıdır';
        this.notify.vertical = true;
        this.notify.color = 'red';
        return;
      }

      if (this.password !== this.passwordConfirm) {
        this.notify.snackbar = true;
        this.notify.text = 'Şifre eşleşmiyor';
        this.notify.vertical = true;
        this.notify.color = 'red';
        return;
      }

      userPassword(params).then((res) => {
        if (res.data.success === 'false') {
          this.notify.snackbar = true;
          this.notify.text = res.data.message;
          this.notify.vertical = true;
          this.notify.color = 'red';
        }
        if (res.data.success === 'true') {
          this.notify.snackbar = true;
          this.notify.text = res.data.message;
          this.notify.vertical = true;
          this.notify.color = 'success';
        }
        this.getUser();
      });
    },
    updateUser() {
      console.log('alooo güncel');
      const params = {
        condiniton: {
          clientId: this.formData.clientId,
        },
        data: this.formData,
      };
      if (Array.isArray(this.formData.userRole)) {
        params.data.userRole = this.formData.userRole;
      } else {
        params.data.userRole = [this.formData.userRole];
      }
      console.log(params);
      console.log(this.$refs.form.validate());

      if (this.$refs.form.validate()) {
        userEdit(params).then((res) => {
          console.log(res);
          if (res.data.success === 'false') {
            this.notify.snackbar = true;
            this.notify.text = res.data.message;
            this.notify.vertical = true;
            this.notify.color = 'red';
          }
          if (res.data.success === 'true') {
            this.notify.snackbar = true;
            this.notify.text = res.data.message;
            this.notify.vertical = true;
            this.notify.color = 'success';
          }
          this.getUser();
        });
      }
    },

    updatePermision() {
      const params = {
        condiniton: {
          clientId: this.formData.clientId,
        },
        data: {
          companyCodeList: this.companyCodeModel,
          prefixList: this.prefixListModel,
        },
      };

      userPermision(params).then((res) => {
        if (res.data.success === 'false') {
          this.notify.snackbar = true;
          this.notify.text = res.data.message;
          this.notify.vertical = true;
          this.notify.color = 'red';
        }
        if (res.data.success === 'true') {
          this.notify.snackbar = true;
          this.notify.text = res.data.message;
          this.notify.vertical = true;
          this.notify.color = 'success';
        }
        this.getUser();
      });
    },

    deleteItem(user) {
      const params = {
        condiniton: {
          clientId: user.clientId,
        },
      };
      userDelete(params).then(() => {
        this.getUser();
      });
    },
  },
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
</style>
